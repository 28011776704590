/* eslint-disable @typescript-eslint/ban-ts-comment */
import { createStore } from 'zustand-x';

export type AlertType = 'error' | 'warning' | 'info' | 'success';

export type THandleOpen = {
  title: string;
  subtitle?: string | null;
  cancelLabel: string;
  confirmLabel: string;
  alertType?: AlertType;
  onConfirmation: () => void;
  onCancel?: () => void;
};

export const modalStore = createStore('modal')(
  {
    show: false,
    confirmLabel: '',
    cancelLabel: '',
    title: '',
    subTitle: '' as string | null,
    alertType: 'error',
    onConfirmation: () => null,
    onCancel: () => null,
  },
  { devtools: { enabled: true } }
).extendActions((set, get) => ({
  showConfirmation: (props: THandleOpen) => {
    set.show(true);
    set.title(props.title);
    set.subTitle(props.subtitle ? props.subtitle : null);
    set.confirmLabel(props.confirmLabel);
    set.cancelLabel(props.cancelLabel);
    set.alertType(props.alertType ?? '');
    // @ts-ignore
    set.onConfirmation(props.onConfirmation);
    // @ts-ignore
    set.onCancel(props.onCancel);
  },
  handleClose: () => {
    set.show(!get.show);
  },
}));
